import { useContext } from 'react'

import PromoAfterItemUploadContext from '../containers/PromoAfterItemUploadContext'

function usePromoAfterItemUploadContext() {
  const promoAfterItemUploadContextProps = useContext(PromoAfterItemUploadContext)

  if (!promoAfterItemUploadContextProps) throw new Error('Missing Profile provider')

  return promoAfterItemUploadContextProps
}

export default usePromoAfterItemUploadContext
