import { BumpableItemPreCheckoutModel } from 'types/models'

import useBumpCheckoutContext from './useBumpCheckoutContext'

const useStartBumpCheckout = () => {
  const { setSelectedItems, setIsPreCheckoutOpen } = useBumpCheckoutContext()

  const startCheckout = (selectedItem?: BumpableItemPreCheckoutModel) => {
    if (selectedItem) {
      setSelectedItems([selectedItem])
    }

    setIsPreCheckoutOpen(true)
  }

  return { startCheckout }
}

export default useStartBumpCheckout
