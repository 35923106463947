'use client'

import { Fragment } from 'react'
import { Badge, SelectionGroup, Text, Spacer } from '@vinted/web-ui'

import List from 'components/List'
import SeparatedList from 'components/SeparatedList'
import useAbTest from 'hooks/useAbTest'
import useTranslate from 'hooks/useTranslate'

import { BumpOptionModel } from 'types/models'
import { AbTestVariant } from 'constants/abtest'

import BumpOptionListItem from './BumpOptionListItem'

type Props = {
  selectedOptionId: string
  bumpOptions: Array<BumpOptionModel>
  isLoading: boolean
  displayPricePreview: boolean
  onSelect: (selectedOption: BumpOptionModel) => void
}

const BumpOptionList = ({
  onSelect,
  bumpOptions,
  selectedOptionId,
  isLoading,
  displayPricePreview,
}: Props) => {
  const translate = useTranslate('bump_items.selection_confirmation_modal')

  const isSelectionGroupAbTestEnabled =
    useAbTest({
      abTestName: 'bumps_precheckout_group_selection',
      shouldTrackExpose: true,
    })?.variant === AbTestVariant.On

  const handleOptionSelect = (option: BumpOptionModel) => () => {
    onSelect(option)
  }

  if (isSelectionGroupAbTestEnabled) {
    const renderTitle = (option: BumpOptionModel) => {
      const { description, isBestValue } = option

      return (
        <SeparatedList separator={<Spacer />}>
          {isBestValue && (
            <Badge
              theme="primary"
              content={
                <Text
                  as="h4"
                  theme="inverse"
                  type={Text.Type.Caption}
                  text={translate('duration.best_value_badge_text')}
                />
              }
            />
          )}
          <Text as="h2" type={Text.Type.Title} text={description} />
        </SeparatedList>
      )
    }

    const renderBody = (body: string | undefined) => {
      if (!body) return null

      return <Text as="h4" type={Text.Type.Caption} text={body} />
    }

    return (
      <SelectionGroup layout={SelectionGroup.Layout.Default} styling={SelectionGroup.Styling.Tight}>
        {bumpOptions.map(option => (
          <SelectionGroup.SelectionItem
            key={option.id}
            title={renderTitle(option)}
            body={renderBody(option.countries)}
            onClick={handleOptionSelect(option)}
            isSelected={option.id === selectedOptionId}
          />
        ))}
      </SelectionGroup>
    )
  }

  return (
    <List>
      {bumpOptions.map(option => (
        <Fragment key={option.id}>
          <BumpOptionListItem
            bumpOption={option}
            onSelect={handleOptionSelect(option)}
            isSelected={option.id === selectedOptionId}
            isLoading={isLoading}
            displayPricePreview={displayPricePreview}
          />
        </Fragment>
      ))}
    </List>
  )
}

export default BumpOptionList
