'use client'

import { MouseEvent } from 'react'
import { useIntl } from 'react-intl'
import { Image, Text, Spacer, Icon } from '@vinted/web-ui'
import { X12, PlusSquareOutlined24 } from '@vinted/monochrome-icons'
import { keyBy } from 'lodash'
import classNames from 'classnames'

import useTranslate from 'hooks/useTranslate'
import { useIsDarkModeEnabledFromCookies } from '@marketplace-web/shared/dark-mode'

import { BUMP_MULTIPLE_ITEM_SELECTION_URL } from 'constants/routes'

import HorizontalScrollArea from 'components/HorizontalScrollArea'

import { urlWithParams } from '_libs/utils/url'
import { formatCurrencyAmount } from '_libs/utils/formatString'

import { BumpOrderItemModel } from 'types/models'
import useBumpCheckoutContext from 'hooks/useBumpCheckoutContext'

type Props = {
  onAddButtonClick?: () => void
  orderItems: Array<BumpOrderItemModel> | undefined
  canAddItems?: boolean
  isOrderLoading?: boolean
}

const ItemSelection = ({
  onAddButtonClick,
  orderItems = [],
  canAddItems = true,
  isOrderLoading = false,
}: Props) => {
  const { locale } = useIntl()
  const translate = useTranslate('bump_items')
  const isDarkMode = useIsDarkModeEnabledFromCookies()

  const { selectedItems, setSelectedItems } = useBumpCheckoutContext()

  const handleAddButtonClick = (event: MouseEvent) => {
    if (!onAddButtonClick) return

    event.preventDefault()
    onAddButtonClick()
  }

  const handleRemoveButtonClick = (itemId: number) => () => {
    const updatedItems = selectedItems.filter(item => item.id !== itemId)

    setSelectedItems(updatedItems)
  }

  const formatNullPriceLabel = (price: string | JSX.Element, orderItem?: BumpOrderItemModel) => {
    return orderItem && !isOrderLoading ? price : <span className="u-visibility-hidden">-</span>
  }

  const renderOldPrice = (orderItem: BumpOrderItemModel) => {
    const payableAmount = parseFloat(orderItem.payable.amount)
    const discountAmount = parseFloat(orderItem.discount.amount)

    if (payableAmount !== 0 && discountAmount === 0)
      return <span className="u-visibility-hidden">-</span>

    return (
      <>
        <Spacer size={Spacer.Size.Small} />
        <Text
          as="h3"
          width={Text.Width.Parent}
          alignment={Text.Alignment.Center}
          type={Text.Type.Subtitle}
          strikethrough
          text={formatCurrencyAmount(orderItem.total, locale)}
        />
      </>
    )
  }

  const renderItemPrice = (orderItem?: BumpOrderItemModel) => {
    const orderPrice = orderItem ? formatCurrencyAmount(orderItem.payable, locale) : ''
    const orderPriceLabel = formatNullPriceLabel(orderPrice, orderItem)
    const orderOldPrice = orderItem ? renderOldPrice(orderItem) : ''
    const orderOldPriceLabel = formatNullPriceLabel(orderOldPrice, orderItem)

    return (
      <>
        <Spacer />
        <Text
          as="h3"
          width={Text.Width.Parent}
          alignment={Text.Alignment.Center}
          type={Text.Type.Subtitle}
          theme="amplified"
          text={orderPriceLabel}
        />
        {orderOldPriceLabel}
      </>
    )
  }

  const renderItems = () => {
    const orderItemsByKey = keyBy(orderItems, 'itemId')

    return selectedItems.map(item => {
      const { id: itemId, thumbnail, title } = item

      const bumpOrderItem = orderItemsByKey[itemId]

      const orderPriceLabel = bumpOrderItem
        ? formatCurrencyAmount(bumpOrderItem.payable, locale)
        : ''
      const itemTitle = title || bumpOrderItem?.title
      const itemPhoto = thumbnail || bumpOrderItem?.imageUrl

      return (
        <HorizontalScrollArea.Item key={itemId}>
          {selectedItems.length === 1 ? (
            <Image
              src={itemPhoto}
              size={Image.Size.X3Large}
              styling={Image.Styling.Rounded}
              alt={`${itemTitle} ${orderPriceLabel}`}
            />
          ) : (
            <div className="bump-items_content-overlay">
              {canAddItems && (
                <button
                  type="button"
                  className={classNames('button-overlay', isDarkMode && 'button-overlay--dark')}
                  onClick={handleRemoveButtonClick(itemId)}
                  data-testid={`bump-remove-item-button-${itemId}`}
                  aria-label={translate('a11y.actions.remove_item')}
                >
                  <Icon
                    name={X12}
                    color={isDarkMode ? Icon.Color.GreyscaleLevel7 : Icon.Color.GreyscaleLevel1}
                  />
                </button>
              )}
              <Image
                src={itemPhoto}
                size={Image.Size.X3Large}
                styling={Image.Styling.Rounded}
                alt={`${itemTitle} ${orderPriceLabel}`}
              />
            </div>
          )}
          {renderItemPrice(bumpOrderItem)}
        </HorizontalScrollArea.Item>
      )
    })
  }

  const renderAddButton = () => {
    const itemIds = selectedItems.map(({ id }) => id)
    const multipleItemSelectionUrl = urlWithParams(BUMP_MULTIPLE_ITEM_SELECTION_URL, {
      item_ids: itemIds,
    })

    if (!canAddItems) return null

    return (
      <div className="u-flexbox u-align-items-center">
        <a
          className="u-flexbox"
          href={multipleItemSelectionUrl}
          onClick={handleAddButtonClick}
          data-testid="bump-add-more-items-button"
        >
          <Icon
            name={PlusSquareOutlined24}
            color="parent"
            aria={{ 'aria-label': translate('a11y.actions.add_items') }}
          />
        </a>
      </div>
    )
  }

  if (!orderItems?.length && !selectedItems[0]?.thumbnail) return null

  return (
    <div className="u-ui-padding-horizontal-large u-padding-top-large">
      <div className="u-flexbox u-align-items-center">
        <div className="u-fill-width u-text-right u-nowrap u-overflow-hidden">
          <HorizontalScrollArea showControls>
            {renderItems()}
            {canAddItems && (
              <HorizontalScrollArea.Item key="add-more-items">
                <div className="u-flexbox u-fill-height u-align-items-center u-ui-padding-horizontal-x-large">
                  {renderAddButton()}
                </div>
              </HorizontalScrollArea.Item>
            )}
          </HorizontalScrollArea>
        </div>
      </div>
    </div>
  )
}

export default ItemSelection
