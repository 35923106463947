'use client'

import { Cell, Radio, Text } from '@vinted/web-ui'
import { useIntl } from 'react-intl'
import classNames from 'classnames'

import useTranslate from 'hooks/useTranslate'

import BestValueBadge from 'components/Bumps/BestValueBadge'
import { formatCurrencyAmount } from '_libs/utils/formatString'

import { BumpOptionModel } from 'types/models'

type Props = {
  isSelected: boolean
  bumpOption: BumpOptionModel
  onSelect: () => void
  isBestValue?: boolean
  isLoading: boolean
  displayPricePreview: boolean
}

const BumpOptionListItem = ({
  onSelect,
  isSelected,
  bumpOption,
  isLoading,
  displayPricePreview,
}: Props) => {
  const { locale } = useIntl()
  const { id, description, isBestValue, countries } = bumpOption
  const translate = useTranslate('bump_items.a11y')
  const isSelectedText = translate(`${isSelected ? 'is_selected' : 'is_not_selected'}`)

  function handleSelect() {
    if (isSelected) return

    onSelect()
  }

  function renderTitle() {
    return (
      <>
        {isBestValue && <BestValueBadge />}
        <Text as="span" text={description} />
      </>
    )
  }

  function renderBody() {
    if (!countries?.length) return null

    return <Text as="h4" type={Text.Type.Caption} theme="muted" text={countries} />
  }

  function renderPrice() {
    if (!displayPricePreview) return null

    return (
      <div className={classNames(isLoading && 'u-visibility-hidden')}>
        <Text as="span" text={formatCurrencyAmount(bumpOption.payable, locale)} />
      </div>
    )
  }

  function renderSuffix() {
    return (
      <Cell
        styling={Cell.Styling.Narrow}
        body={renderPrice()}
        suffix={
          <Radio
            checked={isSelected}
            name={`push_up_option-${id}`}
            value={id}
            onChange={() => undefined}
          />
        }
      />
    )
  }

  function renderPriceAriaLabel() {
    if (!displayPricePreview) return ''

    return formatCurrencyAmount(bumpOption.payable, locale)
  }

  return (
    <Cell
      clickable
      title={renderTitle()}
      body={renderBody()}
      suffix={renderSuffix()}
      styling={Cell.Styling.Narrow}
      onClick={handleSelect}
      aria={{
        'aria-label': `${bumpOption.description} ${renderPriceAriaLabel()} ${isSelectedText}`,
      }}
    />
  )
}

export default BumpOptionListItem
