'use client'

import { ReactNode, useMemo, useState } from 'react'

import { BumpableItemPreCheckoutModel } from 'types/models'

import BumpCheckoutContext from './BumpCheckoutContext'

type Props = {
  children: ReactNode
}

const BumpCheckoutProvider = ({ children }: Props) => {
  const [isPreCheckoutOpen, setIsPreCheckoutOpen] = useState(false)
  const [selectedItems, setSelectedItems] = useState<Array<BumpableItemPreCheckoutModel>>([])

  const value = useMemo(() => {
    return {
      isPreCheckoutOpen,
      selectedItems,
      setIsPreCheckoutOpen,
      setSelectedItems,
    }
  }, [isPreCheckoutOpen, selectedItems, setIsPreCheckoutOpen, setSelectedItems])

  return <BumpCheckoutContext.Provider value={value}>{children}</BumpCheckoutContext.Provider>
}

export default BumpCheckoutProvider
